import React, { useEffect, useState} from 'react'
import './Musicians.css'
import Modal from 'react-modal';
import { useAuth } from '../../Context/AuthContext'
import axios from 'axios'
import { toast } from 'react-toastify'
import { createUserWithEmailAndPassword} from "firebase/auth";
import { auth } from '../../Firebase'
import Switch from "react-switch";

Modal.setAppElement('#root');

export default function Classes() {

  let { currentUser } = useAuth()

  useEffect(() => {
    getMusicians()
  }, [])

  const [musicians, setMusicians] = useState([])
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [number, setNumber] = useState("+44")
  const [saving, setSaving] = useState(false)

  const [modalIsOpen, setIsOpen] = useState(false);
  const [classOpen, setClassOpen] = useState(false);
  const [musicianId, setMusicianId] = useState("");

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openClassModal() {
    setClassOpen(true);
  }

  function closeClassModal() {
    setClassOpen(false);
  }

  const getMusicians = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/musicians/get-musicians`)
    .then((res) => {
      setMusicians(res.data)
    })
    .catch((e) => {
      console.log(e)
    })
  }

  const createMusician = () =>{
      if (!name || !number || !email || number.length < 8){
      toast.error("Missing field")
    } else {
          setSaving(true)
       
          axios.post(`${process.env.REACT_APP_API_URL}/musicians/create-musician-account`, { name, email, number })
          .then(()=>{
            setSaving(false)
            toast.success("Account created successfully!")
            setName("");setEmail("");setNumber("")
            closeModal()
            getMusicians()
          })
          .catch((e)=>{
            console.log(e)
            toast.error("Oops, something went wrong")
            setSaving(false)
          })
      }
    
  }

  const updateMusician = () =>{
    if(!name || !email || !number) {
      toast.error("Please add all information")
    } else {
      axios.post(`${process.env.REACT_APP_API_URL}/musicians/update-musician`, {
        musicianId,
        name,
        email, number
      })
      .then(() => {
        toast.success("Updated successfully!")
        closeClassModal()
        getMusicians()
      })
      .catch((e) => {
        console.log(e)
      })
    }
 
  }

  const handleMusicianClick = (musicianInfo) => {
    openClassModal()
    setName(musicianInfo.name)
    setEmail(musicianInfo.email)
    setNumber(musicianInfo.number)
    setMusicianId(musicianInfo._id)
  }

  const handleAdmin = (checked, uid) => {
    console.log(checked)
    axios.post(`${process.env.REACT_APP_API_URL}/musicians/set-admin`, {uid, checked})
    .then((res)=>{
        toast.success(res.data)
        getMusicians()
    })
    .catch((e)=>console.log(e))
  }

  return (
    <div className='outlet'>
        <button className='otf-btn' style={{margin:'1rem auto'}} onClick={openModal}>Add new musician</button>

        <table id='classes-table'>
            <tr>
                <th>Musician name</th>
                <th>Email</th>
                <th>Number</th>
                <th>Admin</th>
            </tr>

            {musicians.length < 1 
            ?
            <tr >
                <td style={{textAlign:'center', padding: 30}} colSpan={4}>No musicians added</td>
            </tr>
            : 
            musicians.map((musician, i) => {
              return (
              <tr key={i} onClick={() => handleMusicianClick(musician)}>
                <td>{musician.name}</td>
                <td>{musician.email}</td>
                <td>{musician.number}</td>
                <td onClick={(e) =>e.stopPropagation() }>        
                  <Switch onChange={(checked) => handleAdmin(checked, musician._id)} checked={musician.admin ? true : false} />
                </td>
              </tr>
              )

            })
            }   
         
            
        </table>

        <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{...customStyles, overlay: {
          zIndex: 100,
          backgroundColor: 'rgba(70, 70, 70, 0.5)',
        }}}
        contentLabel="Example Modal"
      >

          <input className='classes-input' placeholder='Musican full name' value={name} onChange={(e) => setName(e.target.value)}/>
          <input className='classes-input' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)}/>
          <input className='classes-input' placeholder='Number (inc +44)' value={number} onChange={(e) => setNumber(e.target.value)}/>
        

          <div >
            <button onClick={createMusician} style={{marginRight: 10}} className='otf-btn' disabled={saving}>{saving? 'Saving...': 'Create musician'}</button>
            <button className='inverted-btn' onClick={closeModal}>close</button>     
          </div>
    
      </Modal>

        {/*View musician*/}
      <Modal
        isOpen={classOpen}
        onRequestClose={closeClassModal}
        style={{...customStyles, overlay: {
          zIndex: 100,
          backgroundColor: 'rgba(70, 70, 70, 0.5)',
        }}}
        contentLabel="Example Modal"
      >

          <input className='classes-input' placeholder='Musican full name' value={name} onChange={(e) => setName(e.target.value)}/>
          <input className='classes-input' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)}/>
          <input className='classes-input' placeholder='Number (inc +44)' value={number} onChange={(e) => setNumber(e.target.value)}/>
        

          <div >
            <button onClick={updateMusician} style={{marginRight: 10}} className='otf-btn'>Update musician</button>
            <button className='inverted-btn' onClick={closeClassModal}>close</button>     
          </div>
    
      </Modal>
    </div>
  )
}
const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      display:'flex',
      flexDirection:'column',
      width: 600,
      maxWidth: '95vw',
      maxHeight: '90vh',
      overflow:'scroll'
    },
  };