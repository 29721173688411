import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AdminUI from './Components/AdminUI/AdminUI';
import Calendar from './Components/AdminUI/Calendar';
import Musicians from './Components/AdminUI/Musicians';
import Login from './Components/PublicUI/Login';
import ConfirmGig from './Components/PublicUI/ConfirmGig';
import DeclineGig from './Components/PublicUI/DeclineGig';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RequireAuth from './Components/RequireAuth';
import { AuthProvider } from './Context/AuthContext';
import Profile from './Components/AdminUI/Profile';
import RequireAdminAuth from './Components/RequireAdminAuth';
import Venues from './Components/AdminUI/Venues';
import ArtistCalendar from './Components/ArtistUI/ArtistCalendar';
import ArtistUI from './Components/ArtistUI/ArtistUI';

function App() {
  return (
    <AuthProvider>
    <BrowserRouter>
      <Routes>

          {/*Public routes*/}
          <Route path='/login' element={<Login />} />
          <Route path='/confirm/:id' element={<ConfirmGig />} />
          <Route path='/decline/:id' element={<DeclineGig />} />

          {/*Teacher routes*/}
          <Route element={<RequireAdminAuth  />}>
            <Route path='/' element={<AdminUI />}>
              <Route index element={<Calendar />} />
              <Route path='musicians' element={<Musicians />} />
              <Route path='venues' element={<Venues />} />
              <Route path='profile' element={<Profile />} />

            </Route>
          </Route>
          {/*Artist routes*/}
          <Route element={<RequireAuth  />}>
            <Route path='/artists' element={<ArtistUI />}>
              <Route index element={<ArtistCalendar />} />
            </Route>
          </Route>

      </Routes>
      <ToastContainer position='top-center'/>

    </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
