import React, { useEffect, useState} from 'react'
import './Musicians.css'
import Modal from 'react-modal';
import { useAuth } from '../../Context/AuthContext'
import axios from 'axios'
import { toast } from 'react-toastify'
import map from '../../Assets/map.png'

Modal.setAppElement('#root');

export default function Venues() {

  let { currentUser } = useAuth()

  useEffect(() => {
    getVenues()
  }, [])

  const [venues, setVenues] = useState([])
  const [venueName, setVenueName] = useState("")
  const [venueAddress, setVenueAddress] = useState("")
  const [googleMaps, setGoogleMaps] = useState("")
  const [notes, setNotes] = useState("")

  const [saving, setSaving] = useState(false)

  const [modalIsOpen, setIsOpen] = useState(false);
  const [classOpen, setClassOpen] = useState(false);
  const [venueId, setVenueId] = useState("");

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openClassModal() {
    setClassOpen(true);
  }

  function closeClassModal() {
    setClassOpen(false);
  }

  const getVenues = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/venues/get-venues`)
    .then((res) => {
      setVenues(res.data)
    })
    .catch((e) => {
      console.log(e)
    })
  }

  const createVenue = () =>{
      if (!venueName || !venueAddress ){
      toast.error("Missing field")
    } else {
            axios.post(`${process.env.REACT_APP_API_URL}/venues/create-venue`, { venueName, venueAddress, googleMaps, notes })
            .then(()=>{
              setSaving(false)
              toast.success("Account created successfully!")
              setVenueName("");setVenueAddress("");setGoogleMaps("");setNotes("")
              closeModal()
              getVenues()
           
            })
            .catch((e)=>{
              console.log(e)
              toast.error("Oops, something went wrong")
              setSaving(false)

            })
      }
    
  }

  const updateVenue = () =>{
    if(!venueName || !venueAddress) {
      toast.error("Please add all information")
    } else {
      axios.post(`${process.env.REACT_APP_API_URL}/venues/update-venue`, {
        venueId,
        venueName,
        venueAddress, googleMaps, notes
      })
      .then(() => {
        toast.success("Updated successfully!")
        closeClassModal()
        getVenues()
      })
      .catch((e) => {
        console.log(e)
      })
    }
 
  }

  const handleVenueClick = (venueInfo) => {
    openClassModal()
    setVenueName(venueInfo.venueName)
    setVenueAddress(venueInfo.venueAddress)
    setGoogleMaps(venueInfo.googleMaps)
    setNotes(venueInfo.notes)
    setVenueId(venueInfo._id)
  }



  return (
    <div className='outlet'>
        <button className='otf-btn' style={{margin:'1rem auto'}} onClick={openModal}>Add new venue</button>

        <table id='classes-table'>
            <tr>
                <th>Venue name</th>
                <th>Address</th>
                <th>Google Maps</th>
            </tr>

            {venues.length < 1 
            ?
            <tr >
                <td style={{textAlign:'center', padding: 30}} colSpan={4}>No venues added</td>
            </tr>
            : 
            venues.map((venue, i) => {
              return (
              <tr key={i} onClick={() => handleVenueClick(venue)}>
                <td>{venue.venueName}</td>
                <td>{venue.venueAddress}</td>
                <td>{venue.googleMaps && <a href={venue.googleMaps} target='_blank'><img style={{height: 50, marginLeft: 20}} src={map} alt='Google maps'/></a>}</td>
              
              </tr>
              )

            })
            }   
         
            
        </table>

        <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{...customStyles, overlay: {
          zIndex: 100,
          backgroundColor: 'rgba(70, 70, 70, 0.5)',
        }}}
        contentLabel="Example Modal"
      >

          <input className='classes-input' placeholder='Venue name' value={venueName} onChange={(e) => setVenueName(e.target.value)}/>
          <input className='classes-input' placeholder='Address' value={venueAddress} onChange={(e) => setVenueAddress(e.target.value)}/>
          <input className='classes-input' placeholder='Google maps' value={googleMaps} onChange={(e) => setGoogleMaps(e.target.value)}/>
          <input className='classes-input' placeholder='Notes' value={notes} onChange={(e) => setNotes(e.target.value)}/>


          <div >
            <button onClick={createVenue} style={{marginRight: 10}} className='otf-btn' disabled={saving}>{saving? 'Saving...': 'Create venue'}</button>
            <button className='inverted-btn' onClick={closeModal}>close</button>     
          </div>
    
      </Modal>

        {/*View musician*/}
      <Modal
        isOpen={classOpen}
        onRequestClose={closeClassModal}
        style={{...customStyles, overlay: {
          zIndex: 100,
          backgroundColor: 'rgba(70, 70, 70, 0.5)',
        }}}
        contentLabel="Example Modal"
      >

          <input className='classes-input' placeholder='Venue name' value={venueName} onChange={(e) => setVenueName(e.target.value)}/>
          <input className='classes-input' placeholder='Address' value={venueAddress} onChange={(e) => setVenueAddress(e.target.value)}/>
          <input className='classes-input' placeholder='Google maps' value={googleMaps} onChange={(e) => setGoogleMaps(e.target.value)}/>
          <input className='classes-input' placeholder='Notes' value={notes} onChange={(e) => setNotes(e.target.value)}/>                   

          <div >
            <button onClick={updateVenue} style={{marginRight: 10}} className='otf-btn'>Update venue</button>
            <button className='inverted-btn' onClick={closeClassModal}>close</button>     
          </div>
    
      </Modal>
    </div>
  )
}
const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      display:'flex',
      flexDirection:'column',
      width: 600,
      maxWidth: '95vw',
      maxHeight: '90vh',
      overflow:'scroll'
    },
  };