import React from 'react'
import { Outlet } from 'react-router-dom'
import ArtistNavbar from './ArtistNavbar'

export default function ArtistUI() {
  return (
    <div>
        <ArtistNavbar />

        <Outlet />
    </div>
  )
}
