import React, {useState, useEffect, useRef} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import Modal from 'react-modal';
import './Calendar.css'
import axios from 'axios'
import { useAuth } from '../../Context/AuthContext'
import { toast } from 'react-toastify'
import moment from 'moment'

Modal.setAppElement('#root');

const actTypes = [
  'Vox 1', 'Vox 2', 'Vox 3', 'Keys', 'Sax', 'Trumpet', 'Bass', 'Guitar', 'Drums', 'Sound Engineer', 'Set up Engineer'
]

export default function Calendar() {
  const calendarRef = useRef(null);

  const [date, setDate] = useState("")
  const [venues, setVenues] = useState([])
  const [events, setEvents] = useState([])
  const [bookings, setBookings] = useState([])
  const [musicians, setMusicians] = useState([])
  const [venueChoice, setVenueChoice] = useState("")
  const [modalIsOpen, setIsOpen] = useState(false);
  const [eventModalIsOpen, setEventOpen] = useState(false);
  const [step, setStep] = useState(1)
  //New event state
  const [venueName, setVenueName] = useState("")
  const [venueAddress, setVenueAddress] = useState("")
  const [googleMaps, setGoogleMaps] = useState("")
  const [notes, setNotes] = useState("")
  const [attire, setAttire] = useState("")
  const [venueId, setVenueId] = useState("")
  const [totalFee, setTotalFee] = useState("")
  const [generalArrival, setGeneralArrival] = useState("")
  const [from, setFrom] = useState("")
  const [to, setTo] = useState("")
  const [setTimes, setSetTimes] = useState([])
  const [actsNeeded, setActsNeeded] = useState([{type:'Vox 1', fee: "", arrivalTime: generalArrival}, {type:'Guitar', fee: '', arrivalTime: generalArrival}, {type:'Bass', fee: '', arrivalTime: generalArrival}, {type:'Drums', fee: '', arrivalTime: generalArrival}])
  const [act, setAct] = useState("")
  //View event state
  const [event, setEvent] = useState("")
  const [musicianId, setMusicianId] = useState("")
  const [musicianEmail, setMusicianEmail] = useState("")
  const [musicianNumber, setMusicianNumber] = useState("")
  const [musicianName, setMusicianName] = useState("")

  useEffect(() => {
    getVenues()
    getEvents()
    getBookings()
    getMusicians()
  }, [])

  useEffect(() => {
    if(musicianId){
      let artist = musicians.filter((muso) => muso._id === musicianId)
      if(artist.length > 0){
        let { name, email, number } = artist[0]
        setMusicianEmail(email);setMusicianName(name);setMusicianNumber(number)
      } else {
        setMusicianEmail("");setMusicianName("");setMusicianNumber("");setMusicianId("")
      }
      
    } else {
      setMusicianEmail("");setMusicianName("");setMusicianNumber("");setMusicianId("")

    }
  }, [musicianId])

  useEffect(() => {
    if(generalArrival){
      let updated = actsNeeded.map((act) =>{ return {type: act.type, fee: act.fee, arrivalTime: generalArrival }})
      setActsNeeded(updated)
    }
  }, [generalArrival])

  const getMusicians = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/musicians/get-musicians`)
    .then((res) => {
      setMusicians(res.data)
    })
    .catch((e) => {
      console.log(e)
    })
  }


  const getEvents = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/events/get-events`)
    .then((res) => {
      setEvents(res.data)
      console.log("events", res.data)

    })
    .catch((e) => {
      console.log(e)
    })
  }

  const getBookings = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/events/get-bookings`)
    .then((res) => {
      setBookings(res.data)
    })
    .catch((e) => {
      console.log(e)
    })
  }

  useEffect(() => {
    if(venueChoice){
      let venueInfo = venues.filter((venue) => venue._id === venueChoice )
      setVenueName(venueInfo[0].venueName)
      setVenueAddress(venueInfo[0].venueAddress)
      setGoogleMaps(venueInfo[0].googleMaps)
      setNotes(venueInfo[0].notes)
      setVenueId(venueInfo[0]._id)
    }
  }, [venueChoice])

  const getVenues= () => {
    axios.get(`${process.env.REACT_APP_API_URL}/venues/get-venues`)
    .then((res) => {
      setVenues(res.data)
    })
    .catch((e) => {
      console.log(e)
    })
  }

  function openModal() {
    setIsOpen(true);
  }

  function openEventModal() {
    setEventOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function closeEventModal() {
    setEventOpen(false);
  }


  const handleDateClick = (event) => {
    setDate(event.dateStr)
    openModal()
  }

  const handleEventClick = (event) => {
    console.log(event.event)
    setEvent(event.event)
    setMusicianId(event.event._def.extendedProps.musicianId)
    openEventModal()
  }

  const createEvent = () => {

    let payload = {
     
      date,
      title: venueName,
      start: `${date} ${setTimes[0].from}`,
      setTimes,
      actsNeeded,
      venueName,
      venueAddress,
      googleMaps,
      notes,
      totalFee,
      generalArrival,
      attire
  
    }

      axios.post(`${process.env.REACT_APP_API_URL}/events/create-new-event`, payload)
      .then(() => {
        toast.success("Event created")
        closeModal()
        setVenueChoice("")
        getEvents()
        getBookings()
        clearFields()
      })
      .catch((e) => {
        console.log(e)
      })

    
  }

  const addSet = () => {
    if(!from || !to){
      toast.error("Please add timings")
      } else {
        setSetTimes(prev => [...prev, {from, to}])
        setFrom("");setTo("")
      }
  }

  const addAct = () => {
    if(!act){
      toast.error("Please select act")
      } else {
        setActsNeeded(prev => [...prev, {type:act, fee: '', arrivalTime: generalArrival}])
        setAct("")
      }
  }
  
    const removeSet = (index) => {
      setSetTimes(prev => {
        return prev.filter((_, i) => i !== index)
      })
    }
    const removeAct = (index) => {
      setActsNeeded(prev => {
        return prev.filter((_, i) => i !== index)
      })
    }

    var remainingBudget = actsNeeded.reduce((accum,item) => accum - item.fee, totalFee)

    const updateFee = (index, fee) => {
      setActsNeeded((prevItems) => {
        // Step 3: Use map to create a new array with the updated object
        return prevItems.map((act, i) => {
          if (i === index) {
            return { ...act, fee: fee };
          }
          return act;
        });
      });
    };

    const updateArrival = (index, time) => {
      setActsNeeded((prevItems) => {
        // Step 3: Use map to create a new array with the updated object
        return prevItems.map((act, i) => {
          if (i === index) {
            return { ...act, arrivalTime: time };
          }
          return act;
        });
      });
    };

    const clearFields = () =>{
      setMusicianId("");setMusicianEmail("");setMusicianName("");setMusicianNumber("")
    }

    const updateBooking = () => {
      axios.post(`${process.env.REACT_APP_API_URL}/events/update-booking`, {
        musicianId, musicianName, musicianEmail, musicianNumber, bookingId: event._def.extendedProps._id
      })
      .then((res) => {
        toast.success("Updated")
        clearFields()
        closeEventModal()
        getBookings()
      })
      .catch((e) => {
        console.log(e)
      })
    }
  //Handle monthly profit
  const [turnover, setTurnover] = useState(0);
  const [profit, setProfit] = useState(0);
  const [monthStart, setMonthStart] = useState("");
  const [monthEnd, setMonthEnd] = useState("");


  useEffect(() => {
    getFinancials()
  }, [monthStart, events]);


  const getFinancials = () => {
      let monthEvents = events.filter((event) => moment(event.date).isBetween(moment(monthStart), moment(monthEnd)) )
      //Get turnover
      let monthTurnover = monthEvents.reduce((accumulator, current) => accumulator + current.totalFee, 0 )
      setTurnover(monthTurnover)
      //Get expenses
      let acts = monthEvents.map((event) => event.actsNeeded)
      let flattened = acts.flat()
      let monthExpenses = flattened.reduce((accum, current) => accum + current.fee*1, 0)
      setProfit(monthTurnover - monthExpenses)
  }

  return (
    <div className='outlet'>
        <div style={{width: 1200, maxWidth: '90vw', margin: '1rem auto'}}>
          <div style={{display: 'flex', width: '100%', alignItems:'center'}}>
            <button className='otf-btn' onClick={openModal}>Add event</button>
            <p style={{marginRight: 10}}>Turnover - £{turnover}</p>
            <p>Monthly profit - £{profit}</p>
          </div>
            
            <FullCalendar
                plugins={[ dayGridPlugin, interactionPlugin ]}
                dateClick={(event) => handleDateClick(event)}
                eventSources={[events, bookings]}
                eventClick={(event) => handleEventClick(event)}
                eventDisplay='block'
                eventClassNames={(arg) =>{
                  if (arg.event.extendedProps.status === 'Unassigned') {
                    return [ 'unassigned' ]
                  } else if (arg.event.extendedProps.status === 'Pending') {
                    return [ 'pending' ]
                  } else if(arg.event.extendedProps.status === 'Confirmed'){
                    return [ 'confirmed' ]
                  } else {
                    return [ 'normal' ]
                  }
                }}
                firstDay={1}
                eventContent={function( info ) {
                  let name = info.event.extendedProps.name ? info.event.extendedProps.name : ""
                  return {html: `${moment(info.event.start).format("H:mma")} ${info.event.title} - ${name}`};
              }} 
              datesSet={(info)=>{
                let monthStart = moment((info.start.getTime() + info.end.getTime()) / 2).startOf('month').format()
                let monthEnd = moment((info.start.getTime() + info.end.getTime()) / 2).endOf('month').format()
                setMonthStart(monthStart);setMonthEnd(monthEnd)
              }}
            />
        </div>

      {/*Create event*/}
        <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{...customStyles, overlay: {
          zIndex: 100,
          backgroundColor: 'rgba(70, 70, 70, 0.5)',
        }}}
        contentLabel="Example Modal"
      >
        { step === 1 &&
        <>
          <input className='event-input' type='date' value={date} onChange={(e) => setDate(e.target.value)} />

          <select className='event-input' value={venueChoice} onChange={(e) => setVenueChoice(e.target.value)}>
            <option value='' disabled>Select venue</option>
            {venues.map((venue, i) => {
              return (
                <option key={i} value={venue._id}>
                  {venue.venueName}
                </option>
              )
            })}
          </select>

            {venueChoice &&
              <>
                
              <input className='event-input' placeholder='Venue name' value={venueName} onChange={(e) => setVenueName(e.target.value)}/>
              <input className='event-input' placeholder='Venue address' value={venueAddress} onChange={(e) => setVenueAddress(e.target.value)}/>
              <input className='event-input' placeholder='Google map link' value={googleMaps} onChange={(e) => setGoogleMaps(e.target.value)}/>
              <label>notes</label>
              <input className='event-input' placeholder='Notes' value={notes} onChange={(e) => setNotes(e.target.value)}/>
            
            </>
            }
          
            <button onClick={() => setStep(2)} className='otf-btn' style={{marginRight: 10}} disabled={!date || !venueChoice}>Next</button>
          </>

          }

          {step ===2 &&
          <>
            <label>Total event fee</label>
            <input type='number' className='event-input' value={totalFee} onChange={(e) => setTotalFee(e.target.value)}/>

            <label>General arrival time</label>
            <input type='time' className='event-input' value={generalArrival} onChange={(e) => setGeneralArrival(e.target.value)}/>

            <input placeholder='Attire' className='event-input' value={attire} onChange={(e) => setAttire(e.target.value)}/>

            <p>Set times</p>
            <div>
              <label style={{margin: 5}} >From</label>
              <input style={{margin: 5}} type='time' value={from} onChange={(e) => setFrom(e.target.value)}/>
              <label style={{margin: 5}} >To</label>
              <input style={{margin: 5}}  type='time' value={to} onChange={(e) => setTo(e.target.value)}/>
              <button onClick={addSet} style={{backgroundColor:'#1e1e1e', color: 'white', width: 80, border:'unset', padding: 5, margin: 5}}>add</button>

            </div>
            

            {setTimes.map((time, i) =>{
              return (
                <div key={i} style={{display:'flex', justifyContent:'space-evenly', alignItems:'center'}}>
                  <p>{time.from} - {time.to}</p>
                  <button onClick={() => removeSet(i)}>Remove</button>
                </div>
              )
            })}

              <div style={{margin: '10px 0'}}> 
                <button className='inverted-btn' style={{marginRight: 10}} onClick={() => setStep(1)}>back</button>   
                <button onClick={() => setStep(3)} className='otf-btn' disabled={!totalFee || !generalArrival || setTimes.length < 1} >Next</button>
              </div>
          </>
          }

          {step ===3 &&
            <>
              <h2>Musicians</h2>
              <p>Budget £{totalFee}</p>

              <div>
                <select value={act} onChange={(e) => setAct(e.target.value)}>
                  <option disabled value=''>Select act</option>
                  {actTypes.map((act, i) => {
                    return(
                      <option key={i}>{act}</option>
                    )
                  })}
                </select>
                <button onClick={addAct}>Add act</button>
              </div>
              <table>
                <tr>
                  <th>Act</th>
                  <th>Fee</th>
                  <th>Arrival time</th>
                  <th></th>

                </tr>
              
                {actsNeeded.map((act, i) => {
                  return (
                    <tr key={i}>
                      <td>{act.type}</td>
                      <td><input type='number' value={act.fee} onChange={(e) => updateFee(i, e.target.value)}/></td>
                      <td><input type='time' value={act.arrivalTime} onChange={(e) => updateArrival(i, e.target.value)}/></td>
                      <td><button onClick={() => removeAct(i)}>Remove</button></td>
                    </tr>
                  )
                })}
              </table>

              <h4>Remaining budget = £{remainingBudget}</h4>
              <div style={{margin: '10px 0'}}> 
                <button className='inverted-btn' style={{marginRight: 10}} onClick={() => setStep(2)}>back</button>   
                <button onClick={() => setStep(4)} className='otf-btn' >Next</button>
              </div> 
            </>
          }

          {step ===4 &&
            <>
              <h2>Event summary</h2>

              <table>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
                <tr>
                  <td>Date</td>
                  <td>{moment(date).format('DD/MM/YYYY')}</td>
                </tr>
                <tr>
                  <td>Venue</td>
                  <td>{venueName}</td>
                </tr>
                <tr>
                  <td>Set times</td>
                  <td>{setTimes.map((set, i) => <p>{set.from} - {set.to}</p>)}</td>
                </tr>
                <tr>
                  <td>Total fee</td>
                  <td>£{totalFee}</td>
                </tr>
                {actsNeeded.map((act, i)=> {
                  return (
                    <tr key={i}>
                      <td>{act.type}</td>
                      <td>£{act.fee}</td>
                    </tr>
                  )
                })}
              </table>
              <div style={{margin: '10px 0'}}> 
                <button className='inverted-btn' style={{marginRight: 10}} onClick={() => setStep(3)}>back</button>   
                <button onClick={createEvent} className='otf-btn' >Create event</button>
              </div> 
            </>
          }
         <div>
            <button className='inverted-btn' onClick={closeModal}>close</button>   
         </div>
         
    
      </Modal>
      {/*View event*/}
      <Modal
        isOpen={eventModalIsOpen}
        onRequestClose={closeEventModal}
        style={{...customStyles, overlay: {
          zIndex: 100,
          backgroundColor: 'rgba(70, 70, 70, 0.5)',
          
        }}}
        contentLabel="Example Modal"
      >


          {event._def?.extendedProps.eventId ?
          <>
            <h2>{moment(event.start).format('DD MMM YYYY')}</h2>
            <h3>{event._def?.extendedProps.venueName} - {event.title} </h3>

            <select className='om-input' value={musicianId} onChange={(e) => setMusicianId(e.target.value)}>
              <option>Select musician</option>
              {musicians.map((musician, i) => {
                return (
                  <option value={musician._id} key={i}>
                    {musician.name}
                  </option>
                )
              })}
            </select>

            <button className='otf-btn' onClick={updateBooking}>Update</button>
            
          </>

          :
          <>
            <h2>{moment(event.start).format('DD MMM YYYY')}</h2>
            <h3>{event.title}</h3>

          </>
          }
         

        <button className='inverted-btn' onClick={closeEventModal}>close</button>
    
      </Modal>
        
    </div>
  )
}
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    display:'flex',
    flexDirection:'column',
    width: 600,
    maxWidth: '95vw',
    height: 'auto',
    maxHeight: '90vh',
    overflowY:'scroll',
    textAlign:'center'
  },
};