import React from 'react'
import './Navbar.css'
import logo from '../../Assets/logo-long-clear.png'
import { Link } from 'react-router-dom'
import {  signOut } from "firebase/auth";
import { auth } from '../../Firebase'

export default function ArtistNavbar() {

  const handleLogout = () => {
    signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  }

  return (
    <header>
        <img id='nav-logo' src={logo} alt="OTF Logo"/>


        <button className='inverted-btn' style={{border:'2px solid white', margin: 0}} onClick={handleLogout}>
          Logout
        </button>

    </header>
  )
}
