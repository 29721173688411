import { createContext, useContext, useEffect, useState } from 'react'
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../Firebase';

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider( {children} ) {

    const [currentUser, setCurrentUser] = useState()
    const [admin, setAdmin] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        let unsubscribe= onAuthStateChanged(auth, (user) => {
            if (user) {
                auth.currentUser.getIdTokenResult()
                .then((idTokenResult) => {

                    // Confirm the user is an Admin.
                    if (!!idTokenResult.claims.admin) {
                        // Show admin UI.
                        setAdmin(true)
                        setIsLoading(false)
                        setCurrentUser(user)
                    } else {
                        // Show regular user UI.
                        setAdmin(false)
                        setIsLoading(false)
                        setCurrentUser(user)
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
            } else {
                setCurrentUser(null)
                setIsLoading(false)
            }
          });

          return unsubscribe;
    }, []);

    const value = {
        currentUser, admin
    }

    return (
        <AuthContext.Provider value={value}>
            {!isLoading && children}
        </AuthContext.Provider>
    )
}
