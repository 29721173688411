import React from 'react'
import './Navbar.css'
import logo from '../../Assets/logo-long-clear.png'
import { Link } from 'react-router-dom'

export default function Navbar() {
  return (
    <header>
        <img id='nav-logo' src={logo} alt="Om Yoga Logo"/>

        <nav>
            <Link to={'/'} className='nav-link'>Calendar</Link>
            <Link to={'musicians'} className='nav-link'>Musicians</Link>
            <Link to={'venues'} className='nav-link'>Venues</Link>
            <Link to={'profile'} className='nav-link'>Profile</Link>
        </nav>
        
    </header>
  )
}
