import React, { useEffect, useState} from 'react'
import { useAuth } from '../../Context/AuthContext'
import axios from 'axios'
import './Profile.css'
import {  signOut } from "firebase/auth";
import { toast } from 'react-toastify'
//firebase
import { auth, storage } from '../../Firebase'
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { updateProfile } from "firebase/auth";

export default function Profile() {

    const { currentUser } = useAuth()

    const [img, setImg] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [number, setNumber] = useState("")


    useEffect(() => {
        getUserInfo()
    },[])

    const getUserInfo = () =>{
        axios.post(`${process.env.REACT_APP_API_URL}/musicians/get-musician-by-id`, {_id: currentUser.uid})
        .then((res) =>{
            console.log(res.data)
            setImg(res.data.img)
            setName(res.data.name)
            setEmail(res.data.email)
            setNumber(res.data.number)

        })
        .catch((e) =>{
            console.log(e)
        })
    }

    const handleLogout = () => {
        signOut(auth).then(() => {
          // Sign-out successful.
        }).catch((error) => {
          // An error happened.
        });
      }


     const imgRef = ref(storage, `teachers/profile/${currentUser.uid}`);
     const updateImg = (file) =>{
      if(file?.size > 2097152){
        toast.error("Image must be less than 2mb")
      } else if( !file){
        return
      } else {
        uploadBytes(imgRef, file).then((snapshot) => {
  
          getDownloadURL(imgRef)
          .then((url) => {
            updateProfile(auth.currentUser, {
                photoURL: url
              }).then(() => {
                setImg(url)
                saveProfilePic(url)
              }).catch((error) => {
                  console.log(error)
                toast.error("Error")
              });
              

          })
          .catch((error) => {
              console.log(error)
          });
        });
      }
         
     }

    const saveProfilePic = (url) =>{
        axios.post(`${process.env.REACT_APP_API_URL}/musicians/update-profile-pic`, {_id: currentUser.uid, img: url})
        .then((res) => {
            console.log("Updated")
        })
        .catch((e) => {
            console.log(e)
        })
    }

  return (
    <div className='outlet'>
        <div id='profile-container'>
                <div id='profile-avatar' style={img ? {backgroundImage: `url(${img})`} : {backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/zest-b90d0.appspot.com/o/avatar.png?alt=media&token=729becdf-6299-4cbd-9271-ba1442b8eaa9')`}}>
                    <input type="file" accept="image/*" id='img-input' onChange={(e)=>updateImg(e.target.files[0])}/>
                </div>

            <input className='om-input' placeholder='Name' value={name} onChange={(e) => setName(e.target.value)}/>
            <input className='om-input' placeholder='Email'  value={email} onChange={(e) => setEmail(e.target.value)}/>
            <input className='om-input' placeholder='Number'  value={number} onChange={(e) => setNumber(e.target.value)}/>


            <button className='otf-btn' onClick={handleLogout}>Logout</button>
        </div>
    </div>
  )
}
