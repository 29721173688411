import React, {useState, useEffect, useRef} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import Modal from 'react-modal';
import axios from 'axios'
import { useAuth } from '../../Context/AuthContext'
import { toast } from 'react-toastify'
import moment from 'moment'
import map from '../../Assets/map.png'

Modal.setAppElement('#root');

export default function ArtistCalendar() {

  let { currentUser } = useAuth()

  const [bookings, setBookings] = useState([])
  const [musicians, setMusicians] = useState([])
  const [eventModalIsOpen, setEventOpen] = useState(false);

  //View event state
  const [event, setEvent] = useState("")
  const [musicianId, setMusicianId] = useState("")

  useEffect(() => {
    getBookings()
  }, [])

  const getBookings = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/events/get-bookings-by-musician`, {musicianId: currentUser.uid})
    .then((res) => {
      setBookings(res.data)
    })
    .catch((e) => {
      console.log(e)
    })
  }

  function openEventModal() {
    setEventOpen(true);
  }


  function closeEventModal() {
    setEventOpen(false);
  }


  const handleEventClick = (event) => {
    console.log(event.event)
    setEvent(event.event)
    setMusicianId(event.event._def.extendedProps.musicianId)
    openEventModal()
  }

  const handleConfirm = (bookingId) => {
    axios.post(`${process.env.REACT_APP_API_URL}/events/confirm-booking`, {_id: bookingId})
    .then((res) => {
      toast.success('Booking confirmed')
      closeEventModal()
      getBookings()
    })
    .catch((e) => {
      console.log(e)
    })
  }

  const handleDecline = (bookingId) => {
    axios.post(`${process.env.REACT_APP_API_URL}/events/decline-booking`, {_id: bookingId})
    .then((res) => {
      toast('Booking declined')
      closeEventModal()
      getBookings()
    })
    .catch((e) => {
      console.log(e)
    })
  }


  return (
    <div className='outlet'>
        <div style={{width: 1200, maxWidth: '90vw', margin: '1rem auto'}}>
         
            <FullCalendar
                plugins={[ dayGridPlugin, interactionPlugin ]}
                eventSources={[ bookings]}
                eventClick={(event) => handleEventClick(event)}
                eventDisplay='block'
                eventClassNames={(arg) =>{
                  if (arg.event.extendedProps.status === 'Unassigned') {
                    return [ 'unassigned' ]
                  } else if (arg.event.extendedProps.status === 'Pending') {
                    return [ 'pending' ]
                  } else if(arg.event.extendedProps.status === 'Confirmed'){
                    return [ 'confirmed' ]
                  } else {
                    return [ 'normal' ]
                  }
                }}
                firstDay={1}
                eventContent={function( info ) {
                  let name = info.event.extendedProps.name ? info.event.extendedProps.name : ""
                  return {html: `${moment(info.event.start).format("H:mma")} ${info.event.title} - ${name}`};
              }} 
            
            />
        </div>

      {/*View event*/}
      <Modal
        isOpen={eventModalIsOpen}
        onRequestClose={closeEventModal}
        style={{...customStyles, overlay: {
          zIndex: 100,
          backgroundColor: 'rgba(70, 70, 70, 0.5)',
          
        }}}
        contentLabel="Example Modal"
      >


          {event._def?.extendedProps.eventId &&

          <>

            <h2>{moment(event.start).format('DD MMM YYYY')}</h2>
            <h3>{event._def?.extendedProps.venueName} - {event.title} </h3>

            <table id='overview-table'>
              <tr>
                <th>Arrival time</th>
                <td>{event._def?.extendedProps.arrivalTime}</td>
              </tr>
              {event._def?.extendedProps.setTimes.map((set, i) => {
                return (
                  <tr>
                    <th>Set {i +1}</th>
                    <td>{set.from} - {set.to}</td>
                  </tr>
                )

              })}

              <tr>
                <th>Fee</th>
                <td>£{event._def.extendedProps.fee}</td>
              </tr>
              <tr>
                <th>Dress code</th>
                <td>{event._def.extendedProps.attire}</td>
              </tr>
              <tr>
                <th>Google Maps</th>
                <td><a href={event._def.extendedProps.googleMaps}><img style={{height: 40}} src={map}/></a></td>
              </tr>

            </table>
            {event._def?.extendedProps.status === 'Pending' &&
              <div style={{display:"flex", alignItems:'center', justifyContent:'center', width: '100%'}}>
                <button onClick={() => handleConfirm(event._def.extendedProps._id)} style={{padding: 10, color: 'white', backgroundColor:'green', border:'unset', margin: 10}} >Confirm</button>
                <button onClick={() => handleDecline(event._def.extendedProps._id)} style={{padding: 10, color: 'white', backgroundColor:'crimson', border:'unset', margin: 10}}>Decline</button>

              </div>}
           

            
          </>

         
          }
         

        <button className='inverted-btn' onClick={closeEventModal}>close</button>
    
      </Modal>
        
    </div>
  )
}
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    display:'flex',
    flexDirection:'column',
    width: 600,
    maxWidth: '95vw',
    height: 'auto',
    maxHeight: '90vh',
    overflowY:'scroll',
    textAlign:'center'
  },
};